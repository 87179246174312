import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { FiClock } from "react-icons/fi";
import "./event-card.scss";

const EventCard = ({ id, image, name, day, desc, baslik }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);

  return (
    <>
      <Card className="event-card" style={{ borderColor: "#f9d44380" }}>
        <Card.Body>
          <div className="image">
            <Card.Img style={{ height: "100%" }} src={`/images/${image}`} />
          </div>
          <Card.Subtitle>
            <div>
              <div className="d-flex mt-2" style={{ justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontSize: "11px" }}>
                  <FiClock /> {day}{" "}
                </div>
                <div style={{ fontSize: "13px", paddingLeft: "5px", fontWeight: "600" }}> {baslik} </div>
              </div>
              <h5 className="name pt-3 " style={{ fontWeight: "800", fontSize: "16px" }}>
                {name}
              </h5>
            </div>
          </Card.Subtitle>

          <Card.Title>{desc}</Card.Title>
          <Button onClick={handleShow}><h5>Devamını Oku</h5></Button>
        </Card.Body>
      </Card>

      <Modal show={modalShow} onHide={handleClose} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image">
            <Card.Img style={{ height: "100%" }} src={`/images/${image}`} />
          </div>
          <p>{day} {baslik}</p>
          <p>{desc}</p>
          
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventCard;







/* import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { FiClock } from "react-icons/fi";
import "./resents-card.scss";

const EventCard = ({ image, name, day, desc, baslik }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);

  return (
    <>
      <Card className="event-card" style={{ borderColor: "#f9d44380" }}>
        <Card.Body>
          <div className="image">
            <Card.Img style={{ height: "100%" }} src={`/images/about/${image}`} />
          </div>
          <Card.Subtitle>
            <div>
              <div className="d-flex mt-2" style={{ justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontSize: "11px" }}>
                  <FiClock /> {day}{" "}
                </div>
                <div style={{ fontSize: "13px", paddingLeft: "5px", fontWeight: "600" }}> {baslik} </div>
              </div>
              <h5 className="name pt-3 " style={{ fontWeight: "800", fontSize: "16px" }}>
                {name}
              </h5>
            </div>
          </Card.Subtitle>

          <Card.Title>{desc}</Card.Title>
          <Button onClick={handleShow}>
            <h5>Devamını Oku</h5>
          </Button>
        </Card.Body>
      </Card>

      <Modal show={modalShow} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{desc}</p>
          {/* Add more content as needed */
      /*   </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventCard; */ 

