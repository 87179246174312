import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import "./iletisim.scss";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowAltCircleRight, FaInstagram } from "react-icons/fa";
import PageHeader from "../common/page-header";



const Iletisim = () => {

  





  return (
   <div>
     <h2 className="text-center">
       
        <PageHeader title=" 🟡  BİZE ULAŞIN  🟡"  /> 
      </h2>
    <Container>
      
      <Row>
        <Col>
          <div className="qr  mt-5">
            <a href="https://api.whatsapp.com/send?phone=905312325000">
              <FaWhatsapp
                style={{
                  color: "green",
                  fontSize: "200px",
                }}
              />
            </a>
            
          </div>
          <Col className="qr mt-5">
          <img src="/images/qr/qr.png" alt="whatsapp" width={180} />
           
          </Col>
          <Col className="qr">
          <p className="instagram">
             <a href="https://www.instagram.com/op.dr.ayfersenacar?igsh=MWZmNWE4dzZsZms3bA=="> <FaInstagram /></a>
            </p>
          </Col>
          <Col>
          <div
            className=" text-center"
            style={{ fontSize: "30px" }}
          >
            
            <a href="https://api.whatsapp.com/send?phone=905312325000" style={{textDecoration:"none"}} >
             İletişim <FaArrowAltCircleRight /> 05312325000
            </a>
          </div>
          </Col>
        </Col>
      </Row>
    </Container>
   </div>
  );
};

export default Iletisim;
