import React from "react";
import Slider from "../components/home/slider";
import Spacer from "../components/common/spacer";
import Welcome from "../components/about/welcome";
import Quality from "../components/common/quality";
import Hoca from "../components/common/hoca";
import Resents from "../components/common/resents";
import BackgroundLogo from "../components/common/background-logo";
import Yenilikler from "../components/yenilikler/yenilikler";
import ResentsBlog from "../components/common/resents-blog";
import ImageModal from "../components/player/image-modal";


const HomePage = () => {
  
  return (
    <>
   
      <BackgroundLogo />
      <Slider />
      <ImageModal/>
      <Quality />
      <Spacer/>
      <Welcome />
      <Spacer />
{/*       <Yenilikler /> */}
     
     {/*  <Hoca /> */}
     
      {/* <ResentsBlog /> */}
      {/* <Resents /> */}
      <Spacer />
    </>
  );
};

export default HomePage;
