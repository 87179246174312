import React, { useState } from "react";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { config } from "../../helpers/config";
import MainMenu from "./main-menu";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import "./menubar.scss";


const Menubar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClose = () => setShowMenu(false);

  return (
    <Navbar style={{ background: "black" }} expand="lg" sticky="top">
     
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex">
          <Image
            style={{ width: "95px" }}
            src="/images/hoca/ayfersenhoca-1.png"
            alt={config.project.name}
          />
        </Navbar.Brand>
        <div
          style={{
            textAlign: "center",
            paddingTop: "11px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              fontWeight: "700",
              fontSize: "18px",
              color: "#c9c6c6",
            }}
          >
            Op.Dr.Ayfer ŞEN ACAR
          </div>
          <p style={{ fontSize: "13px", fontWeight: "600" }}>
            Genel Cerrahi Uzmanı
          </p>
        </div>
        <Navbar.Toggle
          aria-controls="mainMenu"
          style={{ backgroundColor: "#ddac43" }}
          onClick={() => setShowMenu(!showMenu)}
        />

        <Navbar.Offcanvas
          id="mainMenu"
          aria-labelledby="offcanvas"
          placement="start"
          show={showMenu}
          onHide={handleMenuClose}
          style={{ color: "#ddac43", backgroundColor: "black" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvas">
              <Image
                style={{ width: "95px" }}
                src="/images/hoca/ayfersenhoca-1.png"
                alt={config.project.name}
              />
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  marginTop: "10px",
                  color: "#c9c6c6",
                }}
              >
                Op.Dr.Ayfer ŞEN ACAR
              </div>
              <p style={{ fontSize: "13px", fontWeight: "600" }}>
                Genel Cerrahi Uzmanı
              </p>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            
            <MainMenu className="mainmenu justify-content-center flex-grow-1 pe-3" onClick={handleMenuClose} />

            <div
              className="btn btn-outline-secondary"
              style={{ marginLeft: "30px", paddingTop: "6px" }}
            >
              <Nav.Link as={Link} to="/iletisim" onClick={handleMenuClose}>
                BİZE ULAŞIN <br /> 0531 232 50 00 <FaWhatsapp style={{color:"green", justifyContent:"center", marginTop:"-4px" }} />
              </Nav.Link>
            </div>
            
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Menubar;
