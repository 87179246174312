import React from "react";
import PageHeader from "../common/page-header";
import { Container } from "react-bootstrap";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import BackgroundLogo from "../common/background-logo";

const Hakkimda = () => {
  return (
    <Container>
      <div>
        <PageHeader title="ÖZGEÇMİŞ" />
        <BackgroundLogo />
        <div className="text-center mb-5">
          <img src="/images/hoca/ayfersenhoca.png" alt="" width={300} />
        </div>
        <div className="text-center">
          <p style={{ fontSize: "20px", color: "#D7DBDD" }}>
            Merhabalar ben Op. Dr. Ayfer ŞEN ACAR genel cerrahi uzmanıyım. Evli
            ve bir çocuk annesiyim. Konya’nın Akşehir ilçesinde dünyaya geldim.
            İlk öğrenim ve liseyi akşehirde tamamladıktan sonra gazi üni tıp
            fak. Kazandım. Sonra Isparta Süleyman demirel üniversitesinde genel
            cerrahi ihtisasımı yaptım. Kilis devlet hastanesinde zorunlu
            hizmetimi bitirdim. Şimdi kendi muayenehanemde hasta kabul
            etmekteyim. <br />
            <strong style={{color:"#ddac43"}}>Soru: Hekime görünmekten çekinen hastalarla nasıl başa çıkıyorsunuz?</strong>  <br />
            <strong style={{color:"#ddac43"}}>Cevap:</strong> Evet, özellikle toplumumuzda mahremiyet çok önemli bir kavram
            ve bu nedenle birçok hasta hekime görünmekte çekinebiliyor.
            Özellikle kadın hastalar, meme ve anal bölge hastalıkları söz konusu
            olduğunda hekime başvurmaktan tereddüt edebiliyorlar. Ancak şunu
            belirtmek isterim ki, hastaların tedaviye geç kalması çok daha büyük
            bir riske yol açabiliyor. Çoğu hasta, bu tür hastalıklar için
            yakınlarından, aile bireylerinden veya komşularından yardım alarak
            ilaç kullanıyorlar. Fakat bu hastalıklar, doğru bir muayene
            yapılmadan çok kolayca birbirine karışabilir ve tedavi edilmezse
            durumlar telafisi zor hale gelebilir. <br /> <strong style={{color:"#ddac43"}}>Soru: Kolon cerrahisi
            konusunda kendinizi nasıl geliştiriyorsunuz?</strong> <br /> <strong style={{color:"#ddac43"}}>Cevap:</strong> Aslında kolon
            (kalınbarsak) ve kanser cerrahisi üzerine yoğunlaşmış olmama rağmen,
            mahremiyet nedeniyle çekinerek başvuran hastalarımıza daha iyi
            hizmet verebilmek amacıyla meme hastalıkları ve anal bölge
            hastalıkları (hemoroid, anal fissür, anal fistül gibi) üzerine de
            kendimi geliştirdim. Bu hastalarımıza en güncel tedavi yöntemlerini
            sunmak adına, son teknolojik cihazlar ve kapalı yöntemler kullanarak
            tedavi sağlıyorum. <br /> <strong style={{color:"#ddac43"}}>Soru: Erken müdahale ile hastalıkların tedavisi
            mümkün mü?</strong> <br /> <strong style={{color:"#ddac43"}}>Cevap:</strong> Kesinlikle! Hastalıklar başladığı anda önlem
            alınırsa, ilerlemeden tedavi edilmesi mümkün olur. Eğer cerrahi
            müdahale gerekiyorsa, artık kapalı yöntemler, lazer ve radyofrekans
            gibi son teknoloji tedavi yöntemleri ile hastalarımıza rahat bir
            tedavi süreci sunabiliyoruz. Bu yöntemler, hem hastanın iyileşme
            sürecini hızlandırıyor hem de daha az iz bırakacak şekilde tedavi
            sağlıyor.
          </p>
        </div>

        {/* EĞİTİM BİLGİLERİ */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Eğitim Bilgileri <FaArrowAltCircleLeft />{" "}
          </h3>

          <p
            className="text-center mt-4 "
            style={{ fontSize: "20px", color: "#D7DBDD" }}
          >
            1994-2002 Nasreddin Hoca İlköğretim Okulu
            <br />
            <br />
            2002-2006 Akşehir Anadolu Öğretmen Lİsesi 2008-2014 Gazi
            Üniversitesi Tıp Fakültesi
            <br />
            <br />
            2016-2021 Isparta Süleyman Demirel Üniversitesi Tıp Fakültesi /
            Genel Cerrahi
            <br />
          </p>
        </div>

        {/* İŞ DENEYİMİ */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> İş Deneyimi <FaArrowAltCircleLeft />{" "}
          </h3>

          <p
            className="text-center mt-4 "
            style={{ fontSize: "20px", color: "#D7DBDD" }}
          >
            29.09.2014-15.12.2014 Konya Akşehir Devlet Hastanesi-Pratisyen Hekim
            <br />
            <br />
            01.07.2016-01.09.2021 Süleyman Demirel Üniversitesi Araştırma ve
            Uygulama Hastanesi Genel Cerrahi A.D.-Araştırma Görevlisi
            <br />
            <br />
            27.11.2021-Halen Kilis Prof. Dr. Alaeddin Yavaşca Devlet Hastanesi
          </p>
        </div>

        {/* SEMİNERLER VE KURSLAR */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Seminerler ve Kurslar{" "}
            <FaArrowAltCircleLeft />{" "}
          </h3>

          <p
            className="text-center mt-4 "
            style={{ fontSize: "20px", color: "#D7DBDD" }}
          >
            Bölgesel ERAS Toplantısı – 15 Eylül 2018
            <br />
            <br />
            Arteriyovenöz Fistül (AVF) Kursu – 24 Kasım 2018
            <br />
            <br />
            Vasküler Cerrahi Kursu – 11 Mayıs 2019
            <br />
            <br />
            Pankreas Hastalıklarında Radyolojik Görüntüleme Sempozyumu – 03
            Eylül 2019
            <br />
            <br />
            Tiroid ve Meme – Kolorektal Cerrahisi Kadavra Kursu – 27 Eylül 2019
            <br />
            <br />
            Tiroid ve Meme – Kolorektal Cerrahisi Kadavra Kursu – 28 Eylül 2019
            <br />
            <br />
            Malnütrisyon ve Nütrisyon Tedavisi konu Toplantı – 19 Ekim 2019
            <br />
            <br />
            HADMEK Deney Hayvanları Kullanım Sertifikası Eğitimi –
            09.11.2019-16.11.2019
            <br />
            <br />
            Uygulamalı İleri Yaşam Desteği, Kardiyopulmoner Resüsitasyon Kursu –
            23 Kasım 2019
          </p>
        </div>

        {/* YURTİÇİ BİLDİRİLERİ */}

        <div className="mt-5">
          <h3 className="text-center">
            {" "}
            <FaArrowAltCircleRight /> Yurtiçi Bildiriler{" "}
            <FaArrowAltCircleLeft />{" "}
          </h3>
          <p style={{ fontSize: "20px", marginTop: "20px", color: "#D7DBDD" }}>
            1 – İleusun Nadir Bir Sebebi: İzole Çekum Nekrozu Melih Can Gül,
            Bekir Sarıcık, Ömer Rıdvan Tarhan, Ayfer Şen EPS-0099 21. Ulusal
            Cerrahi Kongresi 16. Cerrahi Hemşireliği Kongresi 11-15 Nisan 2018
            <br />
            <br />
            2 – İlk 50 Vakadaki Laparoskopik İnsizyonel Herni Deneyimi Mehmet
            Zafer Sabuncuoğlu, Melih Can Gül, Ayfer Şen, Ahmet Contarlı EPS-0365
            21. Ulusal Cerrahi Kongresi 16. Cerrahi Hemşireliği Kongresi 11-15
            Nisan 2018
            <br />
            <br />
            3 – Nüks inguinal hernilerde Laparoskopik Total Ekstraperitoenal
            Herniorafi tedavisinin klinik sonuçları Mehmet Zafer Sabuncuoğlu,
            Ayfer Şen, Serdar Açar, Nesime İnci Eryılmaz EPS-0360 21. Ulusal
            Cerrahi Kongresi 16. Cerrahi Hemşireliği Kongresi 11-15 Nisan 2018
            <br />
            <br />
            4 – Psoas Apsesinin Ender Bir Sebebi : Apendiks Müsinöz Karsinomu
            Melih Can Gül, Mehmet Zafer Sabuncuoğlu, Ayfer Şen, İsa Karaca
            EPS-0746 21. Ulusal Cerrahi Kongresi 16. Cerrahi Hemşireliği
            Kongresi 11-15 Nisan 2018
            <br />
            <br />
            5 – Entübasyona Bağlı Gelişen Özefagus Perforasyonu Melih Can Gül,
            Mehmet Zafer Sabuncuoğlu, Nesime İnci Eryılmaz, Ayfer Şen EPS-0440
            21. Ulusal Cerrahi Kongresi 16. Cerrahi Hemşireliği Kongresi 11-15
            Nisan 2018
            <br />
            <br />
            6 – Eski kontrendikasyon yeni endikasyon mu ? İnsizyonel Herni
            nükserinin laparoskopik onarımı Mehmet Zafer Sabuncuoğlu, İsmail
            Zihni, İsa Sözen, Girayhan Çelik, Ayfer Şen Acar, Melih Can Gül
            EPS-695 22. Ulusal Cerrahi Kongresi 17. Cerrahi Hemşireliği Kongresi
            7-11 Ekim 2020
            <br />
            <br />
            7 – Total ekstraperitoneal İnguinal Herni onarımı : 274 vakalık
            tecrübemiz ve sonuçları Mehmet Zafer Sabuncuoğlu, İsmail Zihni, İsa
            Sözen, Girayhan Çelik, Ayfer Şen Acar, Bilal Turan EPS-694 22.
            Ulusal Cerrahi Kongresi 17. Cerrahi Hemşireliği Kongresi 7-11 Ekim
            2020
            <br />
            <br />
            8 – Nadir görülen bir olgu Zenker Divertikülü Ayfer Şen Acar, İsa
            Karaca, Mehmet Zafer Sabuncuoğlu, İsmail Zihni, İsa Sözen, Girayhan
            Çelik EPS-696 22. Ulusal Cerrahi Kongresi 17. Cerrahi Hemşireliği
            Kongresi 7-11 Ekim 2020
            <br />
            <br />
            9 – Pilonidal Sinüs hastalarında modifiye Limberg flep uygulamasının
            orta dönem sonuçları Mehmet Zafer Sabuncuoğlu, İsmail Zihni, İsa
            Sözen, Girayhan Çelik, Ayfer Şen Acar, Melih Can Gül EPS-700 22.
            Ulusal Cerrahi Kongresi 17. Cerrahi Hemşireliği Kongresi 7-11 Ekim
            2020
            <br />
            <br />
            10 – Kolonun Divertiküler hastalığı : 59 hastalık deneyimlerimiz
            Mehmet Zafer Sabuncuoğlu, İsmail Zihni, Ayfer Şen Acar, Bilal Turan
            EPS-701 22. Ulusal Cerrahi Kongresi 17. Cerrahi Hemşireliği Kongresi
            7-11 Ekim 2020
            <br />
            <br />
            11 – Nüks İnguinal Herni onarımında laparoskopik TEP herniorafi
            Mehmet Zafer Sabuncuoğlu, İsmail Zihni, İsa Sözen, Ayfer Şen Acar,
            Nesime İnci Eryılmaz EPS-702 22. Ulusal Cerrahi Kongresi 17. Cerrahi
            Hemşireliği Kongresi 7-11 Ekim 2020
            <br />
            <br />
            12 – Laparoskopik İnsizyonel Herni onarımı: alternatif mi, ilk
            seçenek mi ?: 109 hastalık deneyimlerimiz Mehmet Zafer Sabuncuoğlu,
            İsmail Zihni, İsa Sözen, Girayhan Çelik, Ayfer Şen Acar, Melih Can
            Gül SS-165 22. Ulusal Cerrahi Kongresi 17. Cerrahi Hemşireliği
            Kongresi 7-11 Ekim 2020
            <br />
            <br />
            13 – Anal Fissür Cerrahi Tedavisinde, Açık ve Kapalı Lateral
            İnternal Sfinkterotomi Uygulanan Hastaların Tedavi Sonuçlarının
            Karışılaştırılması Mehmet Zafer Sabuncuoğlu, İsmail Zihni, İsa
            Sözen, Girayhan Çelik, Bilal Turan, Ayfer Şen Acar SS-142 22. Ulusal
            Cerrahi Kongresi 17. Cerrahi Hemşireliği Kongresi 7-11 Ekim 2020
            <br />
            <br />
            14 –Ürolojik Pelvik Onkolojik Cerrahi Öyküsü Olan İnguinal Herni
            Olgusu İçin eTEP Herniorafi Yaklaşımımız Mehmet Zafer Sabuncuoğlu,
            İsmail Zihni, İsa Sözen, Girayhan Çelik, Bilal Turan, Ayfer Şen Acar
            EPS-657 22. Ulusal Cerrahi Kongresi 17. Cerrahi Hemşireliği Kongresi
            7-11 Ekim 2020
            <br />
            <br />
            15 –Mide Kanserini Taklit Eden Heterotopik Pankreas Olgusu: Olgu
            Sunumu Bilal Turan, Ayfer Şen Acar, Mehmet Zafer Sabuncuoğlu, İsmail
            Zihni, İsa Sözen, Girayhan Çelik EPS-620 22. Ulusal Cerrahi Kongresi
            17. Cerrahi Hemşireliği Kongresi 7-11 Ekim 2020
            <br />
            <br />
            16 – İlk Klinik Bulgusu Olarak Pelvik Kemik Metastazı ile Ortaya
            Çıkan Foliküler Tiroid Karsinomu Vakası İsmail Zihni, Mehmet Zafer
            Sabuncuoğlu, İsa Sözen, Girayhan Çelik, Bilal Turan, Ayfer Şen Acar,
            Recep Çetin EPS-615 22. Ulusal Cerrahi Kongresi 17. Cerrahi
            Hemşireliği Kongresi 7-11 Ekim 2020
            <br />
            <br />
            17 – Spontan Rektus Kılıf Hematomu Olgularına Yaklaşımımız Bilal
            Turan, Ayfer Şen Acar, Mehmet Zafer Sabuncuoğlu, İsmail Zihni, İsa
            Sözen, Girayhan Çelik EPS-596 22. Ulusal Cerrahi Kongresi 17.
            Cerrahi Hemşireliği Kongresi 7-11 Ekim 2020
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Hakkimda;
