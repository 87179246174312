/* import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import cizim from "../../helpers/data/cizim.json";
import "./quality.scss"



const Quality = () => {
  return (
    <div className="quality">
      <Container>
        <Row xs={2} sm={2} md={3} lg={4} xl={6}  className="text-center g-2">
          {cizim.map((item) => (
            <Col key={item.id}>
              <Card>
                <Card.Body>
                  <Card.Img
                    src={`/images/cizim/${item.image}`}
                  />
                  <div className="title">
                  
                  <Card.Subtitle style={{fontSize:"16px", textAlign:"center" }}>{item.title} <br /> {item.desc}</Card.Subtitle>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Quality; */





/* import React, { useState } from "react";
import { Card, Col, Container, Row, Modal, Button } from "react-bootstrap";
import cizim from "../../helpers/data/cizim.json";
import "./quality.scss";

const Quality = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <div className="quality">
      <Container> 
        <Row xs={2} sm={2} md={3} lg={4} xl={6} className="text-center g-2">
          {cizim.map((item) => (
            <Col key={item.id}>
              <Card onClick={() => handleImageClick(item.image)}>
                <Card.Body>
                  <Card.Img src={`/images/${item.image}`} />
                  <div className="title">
                    <Card.Subtitle style={{ fontSize: "16px", textAlign: "center" }}>
                      {item.title} <br /> {item.desc}
                    </Card.Subtitle>
                  </div>
           </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>
          {selectedImage && <img src={`/images/${selectedImage}`} alt="Image" className="img-fluid" />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Quality; */

import React, { useState } from "react";
import { Card, Col, Container, Row, Modal, Button } from "react-bootstrap";
import cizim from "../../helpers/data/cizim.json";
import "./quality.scss";

const Quality = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Seçilen öğeyi tutmak için state

  const handleImageClick = (item) => {
    setSelectedItem(item); // Tüm öğeyi sakla
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  return (
    <div className="quality">
      <Container>
        <Row xs={2} sm={2} md={2} lg={4} xl={5} className="text-center g-2">
          {cizim.map((item) => (
            <Col key={item.id}>
              <Card onClick={() => handleImageClick(item)}> {/* Tüm öğeyi gönder */}
                <Card.Body>
                  <Card.Img src={`/images/${item.image}`} />
                  <div className="title">
                    <Card.Subtitle style={{ fontSize: "16px", textAlign: "center" }}>
                      {item.title} <br /> 
                    </Card.Subtitle>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton />
        <Modal.Body >
          {selectedItem && (
            <>
              <img
                src={`/images/${selectedItem.image}`}
                alt="Selected"
                className="img-fluid mb-3"
              />
              <h5 className="text-center">{selectedItem.title}</h5> {/* Başlık */}
              <p>{selectedItem.desc}</p> {/* Açıklama */}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Quality;





